import {
  Card,
  DisplayText,
  Icon,
  Layout,
  Page,
  Stack,
  TextStyle
} from '@shopify/polaris';
import {
  GlobeMajor,
  PlayCircleMajor,
  QuestionMarkMajor
} from '@shopify/polaris-icons';

import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { authState } from '../atoms';
import DropKickTitleBar from '../components/DropKickTitleBar';
import { AuthInfo } from '../types/AuthInfo';

const DashboardLayout = styled.div`
  p {
    margin-bottom: 20px;
  }
  .step-title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 4px;
    color: #333;
  }
  .step-subtitle .step-message {
    margin-bottom: 8px;
  }
  .external-links {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 0.375rem;
    a {
      padding: 18px;
      border-radius: 0.375rem;
      margin-bottom: 12px;
      background: #eee;
      width: 100%;
      display: block;
      text-decoration: none;
      font-size: 14px;
      color: #666;
      line-height: 20px;
    }
    a:hover {
      background: #ddd;
    }
  }
  .Polaris-Layout__Section:first-child {
    flex: 1 1 14rem;
  }
`;

const NUMBER_STEPS = 3;

const Dashboard: React.FC = (): JSX.Element => {
  const authInfo: AuthInfo = useRecoilValue(authState);
  let completeStepCount = authInfo.auth.welcomeTourStatus
    ? JSON.parse(authInfo.auth.welcomeTourStatus).length
    : 0;
  if (completeStepCount > NUMBER_STEPS) {
    completeStepCount = NUMBER_STEPS;
  }
  return (
    <>
      <DropKickTitleBar title="Dashboard" />
      <Page title={'Dashboard'} titleHidden={true} fullWidth={false}>
        <>
          <br />
          <br />
          <Card sectioned>
            <DashboardLayout>
              <Stack>
                <Stack.Item></Stack.Item>
              </Stack>
              <Layout>
                <Layout.Section oneHalf>
                  <DisplayText size="small">
                    Welcome to
                    <br />
                    VelvetShip
                  </DisplayText>
                  <p>
                    <h3>
                      <b>Important Update Effective November 1st, 2024</b>
                    </h3>
                    <p>
                      Due to circumstances beyond our control, we have been
                      forced to shutdown the Shopify app and will not longer be
                      able to fulfill any orders. We appreciate your business
                      and hope you understand.
                    </p>
                    <p>
                      We recommend finding another suitable dropshipping partner
                      on the{' '}
                      <ul>
                        <li>
                          <a
                            style={{
                              color: 'purple',
                              textDecoration: 'underline'
                            }}
                            href="https://apps.shopify.com/search?q=adult%20dropshipping"
                          >
                            Shopify app store.
                          </a>
                        </li>
                      </ul>
                    </p>
                    <p>
                      We also recommend removing any products that were imported
                      from our catalog into your store. You can do this going to
                      your products page, and doing a search for all products
                      tagged with{' '}
                      <b style={{ fontWeight: 'bold' }}>VelvetShip-Import</b>.
                      This will allow you select all the products and remove
                      them from your store.
                    </p>
                    <p>
                      If you have any questions, please reach out to us at{' '}
                      <a
                        style={{ color: 'purple', textDecoration: 'underline' }}
                        href={`mailto:support@velvetship.com`}
                      >
                        support@velvetship.com
                      </a>
                    </p>
                  </p>
                </Layout.Section>
                <Layout.Section oneHalf>
                  <div className="external-links">
                    <DisplayText size="small">Helpful Links</DisplayText>
                    <p>More help to kickstart your store!</p>
                    <a
                      href={authInfo.app.videoUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Stack wrap={false} alignment="center">
                        <Stack.Item>
                          <Icon source={PlayCircleMajor} color="primary" />
                        </Stack.Item>
                        <Stack.Item>
                          <TextStyle variation="strong">
                            Learn How It Works
                          </TextStyle>
                          <div>
                            <TextStyle variation="subdued">
                              Watch a short intro video
                            </TextStyle>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </a>
                    <a
                      href={authInfo.app.faqUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Stack wrap={false} alignment="center">
                        <Stack.Item>
                          <Icon source={QuestionMarkMajor} color="primary" />
                        </Stack.Item>
                        <Stack.Item>
                          <TextStyle variation="strong">
                            Have Questions?......
                          </TextStyle>
                          <div>
                            <TextStyle variation="subdued">
                              Get answers in our FAQ
                            </TextStyle>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </a>
                    <a
                      href={authInfo.app.websiteUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Stack wrap={false} alignment="center">
                        <Stack.Item>
                          <Icon source={GlobeMajor} color="primary" />
                        </Stack.Item>
                        <Stack.Item>
                          <TextStyle variation="strong">
                            Want to Know More?
                          </TextStyle>
                          <div>
                            <TextStyle variation="subdued">
                              Learn more about us
                            </TextStyle>
                          </div>
                        </Stack.Item>
                      </Stack>
                    </a>
                  </div>
                </Layout.Section>
              </Layout>
            </DashboardLayout>
          </Card>
        </>
        <br />
        <br />
        <br />
        <br />
      </Page>
    </>
  );
};

export default Dashboard;
